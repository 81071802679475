import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [backendUrl, setBackendUrl] = useState(
    "127.0.0.1:3030/get-page-access-token"
  );

  function handleError(error) {
    console.log(error);
  }

  function handleSuccess(response) {
    console.log(response);
    setToken(response.authResponse.accessToken);
    setUserId(response.authResponse.userID);
    fetch(`http://${backendUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accessToken: response.authResponse.accessToken,
        userId: response.authResponse.userID,
      }),
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>backend url</p>
        {/* <input value={backendUrl} onChange={handleChange}></input> */}
        <p>token</p>
        <div>{token}</div>
        <p>userId</p>
        <div>{userId}</div>
        <FacebookLoginButton />
      </header>
    </div>
  );
}

const appId = "821129056103114";
const config_id = "831595755061089";

const FacebookLoginButton = () => {
  const [isLoggedin, setIsLoggedin] = useState(false);

  const onLoginClick = () => {
    console.log("clicked");
    window.FB.login(
      function (response) {
        console.log(response);
      },
      {
        config_id, // configuration ID goes here
        response_type: "code", // must be set to 'code' for SUAT
      }
    );
  };

  const statusChangeCallback = (response) => {
    console.log(response, "statusChangeCallback");
  };

  // init fb sdk
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v17.0",
      });

      window.FB.AppEvents.logPageView();
      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <div>
      <button onClick={onLoginClick}>Login with Facebook</button>
    </div>
  );
};


export default App;
